/* ================================= Testimonials Section Css Start ==================================== */
.testimonials-item {
    &__desc {
        @media (min-width: 768px) {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.testimonials-slider {
    @media (min-width: 768px) {
        position: absolute;
        top: 0;
        left: 25.50%;
    }
    @media (max-width: 1299px) {
        left: 34%;
    }
    @media (max-width: 767px) {
        left: 0;
    }
}

.testimonials-thumbs {
    overflow: hidden;
    &__img {
        max-height: 350px;
        height: 100%;
        width: 100%;
        transition: .6s linear;
        position: relative;
        img {
            object-position: top;
        }
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(2deg, #000000e8, transparent);
            visibility: hidden;
            opacity: 0;
            transition: .2s linear;
        }
    }
    
    &:hover {
        .testimonials-thumbs__img::before, .testimonials-thumbs__content {
            visibility: visible !important;
            opacity: 1 !important;
        }
    }
}

.testimonials {
    .slick-slider .slick-track {
        display: flex;
        align-items: end;
    }
    .slick-slide {
        &.slick-active.slick-current .testimonials-thumbs {
            overflow: unset !important;
            &__img {
                object-fit: cover;
                max-height: 100%;
            }
        }
    }
}
.testimonials-thumbs-slider  {
    .slick-slide {
        height: 630px !important;
    }
}
.testimonials-slider {
    .slick-slide {
        @media (min-width: 768px) {
            height: 630px !important;
        }
    }
}
/* ================================= Testimonials Section Css End ==================================== */