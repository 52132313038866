.badge-notification {
    margin-bottom: 6px;
    position: absolute;
    inset-block-start: -18px;
    inset-inline-start: 0;
    animation: notification .8s infinite;
    &::before {
        position: absolute;
        content: "";
        width: 11px;
        height: 6px;
        background-color: inherit;
        top: calc(100% - 1px);
        clip-path: polygon(0% 0%, 100% 0%, 0% 100%, 0% 100%);
    }
}

.badge-style-two::before {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    background: var(--main-two-600);
    border-radius: 2px;
    inset-inline-start: -4px;
    transform: translateY(-50%) rotate(45deg);
    top: 50%;
}


@keyframes notification {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: .3;
    }
}