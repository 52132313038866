/* ===================================== Vendor Css Start ================================== */
.vendor-card {
  background-color: var(--main-100);
  -webkit-mask-image: url("~/public/assets/images/shape/box-shape2.png");
  mask-image: url("~/public/assets/images/shape/box-shape2.png");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background: #f4f6e6;
  mask-position: top;
  mask-size: 100% 100%;
  &.style-two {
    -webkit-mask-image: url("~/public/assets/images/shape/box-shape3.png");
    mask-image: url("~/public/assets/images/shape/box-shape3.png");
  }
  &__logo {
    max-width: 66px;
  }
  &__item {
    max-width: 64px;
    max-height: 64px;
    aspect-ratio: 1;
    width: 100%;
    height: 100%;
  }
  .vendor-card__list {
    &.style-two {
      .vendor-card__item {
        max-width: 88px;
        max-height: 88px;
        border-radius: 8px !important;
      }
      .slick-slide {
        margin: 0 4px !important;
      }
    }
  }
}
.vendor-card-wrapper div[class*="col"] {
  &:nth-child(8n + 1) {
    .vendor-card {
      background: #f4f6e6;
    }
  }
  &:nth-child(8n + 2) {
    .vendor-card {
      background: #e6f6f6;
    }
  }
  &:nth-child(8n + 3) {
    .vendor-card {
      background: #f6e6f6;
    }
  }
  &:nth-child(8n + 4) {
    .vendor-card {
      background: #f8eae4;
    }
  }
  &:nth-child(8n + 5) {
    .vendor-card {
      background: #f8eae4;
    }
  }
  &:nth-child(8n + 6) {
    .vendor-card {
      background: #dee6f3;
    }
  }
  &:nth-child(8n + 7) {
    .vendor-card {
      background: #daf2db;
    }
  }
  &:nth-child(8n + 8) {
    .vendor-card {
      background: #ebf1da;
    }
  }
}
/* ===================================== Vendor Css End ================================== */
