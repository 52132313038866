/* ================== Section Heading Css Start ========================= */
.section-heading {
    margin-bottom: 40px;
    @include lg-screen {
        margin-bottom: 24px;
    }

    &.writing-mode {
        @media (min-width: 1200px) {
            writing-mode: vertical-lr;
            transform: rotate(180deg);
        }
        @media (max-width: 1199px) {
            margin-bottom: 60px !important;
        }
    }
}
/* ================== Section Heading Css End ========================= */