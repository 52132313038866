// background
@each $color, $shades in $colors {
    @each $shade, $value in $shades {
      .bg-#{$color}-#{$shade} {
        background-color: var(--#{$color}-#{$shade}) !important;
      }
    }
}

// hover background
@each $color, $shades in $colors {
    @each $shade, $value in $shades {
      .hover-bg-#{$color}-#{$shade} {
        &:hover {
            background-color: var(--#{$color}-#{$shade}) !important;
        }
      }
    }
  }

  .hover-bg {
    &-white {
      &:hover {
        background-color: hsl(var(--white)) !important;
      }
    }
  }


.bg-light-purple {
  background: #ECDBEC !important;
}

.bg {
  &-yellow-light {
    background-color: #FFFCE1 !important;
  }
  &-danger-light {
    background-color: #FFEBEE !important;
  }
  &-purple-light {
    background-color: #F5E7FC !important;
  }
  &-danger-light {
    background-color: #FFE2F5 !important;
  }
  &-warning-light {
    background-color: #FBEEDD !important;
  }
  &-success-light {
    background-color: #F6F5DB !important;
  }
}