/* ================================= Padding Css Start =========================== */
@each $property, $value in $spaces {
  .p-#{$property} {
    padding: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .px-#{$property} {
    padding-inline: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .py-#{$property} {
    padding-block: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .ps-#{$property} {
    padding-inline-start: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .pe-#{$property} {
    padding-inline-end: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .pt-#{$property} {
    padding-block-start: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .pb-#{$property} {
    padding-block-end: var(--size-#{$property}) !important;
  }
}

.p--24 {
  padding: 24px;
}

.p-56-px {
  padding: clampCal(8, 56, 1299, 1699);
}

.pt-0 {
  padding-block-start: 0!important;
}

.pb-0 {
  padding-block-end: 0!important;
}

.ps-0 {
  padding-inline-start: 0!important;
}

.pe-0 {
  padding-inline-end: 0!important;
}


.py-100 {
  padding-block-start: 40px !important;
  padding-block-end: 40px !important;
  @media (min-width: 992px) {
    padding-block-start: 60px !important;
    padding-block-end: 60px !important;
  }
  @media (min-width: 1200px) {
    padding-block-start: 80px !important;
    padding-block-end: 80px !important;
  }
  @media (min-width: 1400px) {
    padding-block-start: 100px !important;
    padding-block-end: 100px !important;
  }
}


.py-80 {
  padding-block-start: 60px !important;
  padding-block-end: 60px !important;
  @media (min-width: 992px) {
    padding-block-start: 80px !important;
    padding-block-end: 80px !important;
  }
}
.pt-80 {
    padding-block-start: 60px !important;
    @media (min-width: 992px) {
    padding-block-start: 80px !important;
  }
}
.pb-80 {
    padding-block-end: 60px !important;
    @media (min-width: 992px) {
    padding-block-end: 80px !important;
  }
}

.py-60 {
    padding-block-start: 30px !important;
    padding-block-end: 30px !important;
    @media (min-width: 576px) {
    padding-block-start: 40px !important;
    padding-block-end: 40px !important;
  }
    @media (min-width: 992px) {
    padding-block-start: 60px !important;
    padding-block-end: 60px !important;
  }
}
.pt-60 {
    padding-block-start: 30px !important;
    @media (min-width: 576px) {
    padding-block-start: 40px !important;
  }
    @media (min-width: 992px) {
    padding-block-start: 60px !important;
  }
}
  .pb-60 {
    padding-block-end: 30px !important;
    @media (min-width: 576px) {
    padding-block-end: 40px !important;
  }
    @media (min-width: 992px) {
    padding-block-end: 60px !important;
  }
}
/* ================================= Padding Css End =========================== */
